
table.comparison  {
  border-collapse: collapse;
  font-size: 10px;
  width: 100%;
  max-width: 100%;

  caption {
    font-weight: bold;
  }

  th,
  td {
    border: 1px solid #CCC;
    text-align: left;
    padding: 2px 3px;
  }

}

.previewImage {
  max-width: 100%;
  height: auto;
  cursor: zoom-in;
}

.orderButton {
  text-align: center;
  margin-top: 1.5em;
}
