.comparison {
  table {
    width: 400px;
    position: absolute;
    border-collapse: collapse;
    font-size: 10px;

    th,
    td {
      border: 1px solid #CCC;
      text-align: left;
      padding: 2px 3px;
    }

    thead tr:first-child th:first-child {
      border-left: 0;
      border-top: 0;
    }
  }

  a,
  a:hover,
  a:focus {
    text-decoration: none;
  }
}
