:root {
  --cross-circle-fill-color: #ed4337;
  --cross-cross-fill-color: #fff;
  --cross-thickness: 5;
}

.cross {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  stroke: var(--cross-cross-fill-color);
  stroke-width: var(--cross-thickness);
  stroke-miterlimit: 10;
  //box-shadow: inset 0 0 0 var(--cross-fill-color);
  animation: circle_fill 0.4s ease-in-out 0.4s forwards, cross_scale 0.3s ease-in-out 0.9s both;
}

.cross__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: var(--cross-thickness);
  stroke-miterlimit: 10;
  stroke: var(--cross-circle-fill-color);
  fill: none;
  animation: circle_stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.cross__cross {
  stroke: none;
  fill: #fff;
}

@keyframes circle_stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes cross_scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes circle_fill {
  100% {
    box-shadow: inset 0 0 0 102vh var(--cross-circle-fill-color);
  }
}
