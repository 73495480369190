@import "../../scss/base";
@import "../../fonts/eras-light-itc";

$primary-font-color: #666;

html {
  font-family: Verdana, sans-serif;
}

body {
  background: #f1f1f1;
  margin: 0;
  padding : 0;
  color: $primary-font-color;
}

a {
  color: $primary-font-color;
  &:hover, &:focus {
    color: darken($primary-font-color, 10%);
  }
}

h1, h2, h3  {
  font-family: ErasLightITC, sans-serif;
  font-weight: normal;
  margin-bottom: 0;

  &:first-child {
    margin-top: 0;
  }
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}

p {
  line-height: 1.5em;
}

.Layout {
  .wrapper {
    width: 100%;
    margin: 0;
    position: relative;
    min-height: 10rem;

    @include bp-up(lg) {
      width: 1000px;
      margin: 1.5rem auto;
    }

    .container {
      padding: 1rem 1rem 0;


      @media screen and (max-height: 400px) {
        padding-top: 0;
      }
    }
  }
}

.Logo {
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #efefef;

  //color: red;
  //
  //@include bp-up(sm) {
  //  color: purple;
  //}
  //
  //@include bp-up(md) {
  //  color: blue;
  //}
  //
  //@include bp-up(lg) {
  //  color: green;
  //}
  //
  //@include bp-up(xl) {
  //  color: inherit;
  //}

  svg {
    width: 33rem;
    max-width: calc(100% - 2rem);
    margin: 0 1rem 1rem;

    @include bp-up(lg) {
      margin: 1rem;
    }
  }
}

.LogoHideLowScreen {
  @media screen and (max-height: 400px) {
    display: none;
  }
}

.Footer {
  font-size: 11px;

  .right {
    float: right;
    margin-right: 1em;
  }

  nav ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    color: #666;

    li {
      &:not(:last-child) {
        padding-right: .5em;

        &::after {
          content: "|";
          padding-left: .5em;
        }
      }

      a {
        color: inherit;

        &:hover, &:focus {
          color: #333;
        }
      }
    }
  }
}
